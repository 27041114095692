<template>
  <div>
    <index path="/datareport/customerGrowth"></index>
    <div style="position: absolute; right: 1%; width: 81%; top: 1%">
      <div>
        <div
          class="tools-bar"
          style="
            float: right;
            padding-top: 1vh;
            padding-bottom: 1vh;
            font-size: 1.6vh;
          "
        >
          <el-select
            v-model="supplierId"
            placeholder="服务商"
            size="mini"
            style="width: 9vw; padding-right: 0.7vw"
            @change="selectSupplier"
          >
            <el-option
              v-for="item in providers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="relation"
            placeholder="客户关系"
            size="mini"
            style="width: 9vw; padding-right: 0.7vw"
            @change="selectSupplier"
          >
            <el-option
              v-for="item in relationList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-date-picker
            size="mini"
            style="width: 15vw"
            :clearable="false"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
            style="margin-left: 0.7vw"
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="search"
            >搜索</el-button
          >
        </div>
      </div>
      <div id="main" style="width: 100%; margin-top: 7vh; height: 80vh"></div>
    </div>
  </div>
</template>

<script>
import index from "@/views/datareport/index";
import { getProviders, getCustomerGrowthData } from "@/api/datareport";
import echarts from "echarts";

export default {
  components: {
    index,
  },
  data() {
    let date = new Date();
    date.setDate(1);
    date.setMonth(0);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return {
      relation: "直属",
      relationList: [
        { id: 1, name: "直属" },
        { id: 2, name: "含从属" },
      ],
      providers: [],
      supplierId: null,
      date: [date, new Date()],
    };
  },
  mounted() {
    this.getProviders();
    // this.initChart();
  },
  methods: {
    getProviders() {
      getProviders().then((res) => {
        if (res.data.state == "ok") {
          this.providers = res.data.data;
          this.supplierId = res.data.data[0].id;
          this.initChart();
        }
      });
    },
    selectSupplier() {
      this.initChart();
    },
    search() {
      this.initChart();
    },
    initChart() {
      let begin =
        this.date && this.date.length > 1 && this.date[0]
          ? new Date(+new Date(this.date[0]) + 8 * 3600 * 1000)
              .toISOString()
              .replace(/T/g, " ")
              .replace(/\.[\d]{3}Z/, "")
          : null;
      let end =
        this.date && this.date.length > 1 && this.date[1]
          ? new Date(+new Date(this.date[1]) + 8 * 3600 * 1000)
              .toISOString()
              .replace(/T/g, " ")
              .replace(/\.[\d]{3}Z/, "")
          : null;
      let params = {
        supplierId: this.supplierId,
        relation: this.relation,
        timeBegin: begin,
        timeEnd: end,
      };
      let seriesData = [];
      let xAxisData = []; //x轴数据
      let add = []; //新增
      let summary = []; //汇总
      getCustomerGrowthData(params).then((res) => {
        if (res.data.state == "ok") {
          let data = res.data.data;
          let count = 0;
          data.forEach((element) => {
            if (element.name != undefined) {
              count = element.data;
            }
            if (element.count != undefined) {
              add.push(element.count);
              xAxisData.push(element.m);
              count = count + element.count
              summary.push(count);
            }
          });
          seriesData.push(add);
          seriesData.push(summary);
          this.setCustomerGrowtOption(xAxisData,seriesData)
        }
      });
    },
    setCustomerGrowtOption(xAxisData,seriesData) {
      var customerGrowthDom = document.getElementById("main");
      var customerGrowthChart = echarts.init(customerGrowthDom);
      var customerOption = {
        grid: {
          left: "3%",
          right: "3%",
          bottom: "2%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          left: "center",
          textStyle: {
            // color: "#ffffff",
          },
          data: ["新增", "汇总"],
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                // color: "#F5F5F5",
              },
            },
            axisTick: {
              show: false,
            },
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            max: this.calMax([seriesData[0],seriesData[1]]),
            axisLine: {
              show: true,
              lineStyle: {
                // color: "#F5F5F5",
              },
            },
            axisLabel: {
              formatter: "{value} ",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "新增",
            type: "bar" /*设置柱状图颜色*/,
            itemStyle: {
              normal: {
                color: "#FFC40A",
                /*信息显示方式*/
                label: {
                  show: true,
                  position: "top",
                  formatter: "{c}",
                },
              },
            },
            data: seriesData[0],
          },
          {
            name: "汇总",
            type: "line",
            symbol: "none",
            smooth: true,
            color: "#09B7EE",
            itemStyle: {
              normal: {
                lineStyle: "#09B7EE",
              },
            },
            data: seriesData[1],
          },
        ],
      };
      customerGrowthChart.setOption(customerOption);
      window.onresize = function () {
        customerGrowthChart.resize();
      };
    },
    //计算最大值
    calMax(arr) {
      let max = 0;
      arr.forEach((el) => {
        el.forEach((el1) => {
          if (!(el1 === undefined || el1 === "")) {
            if (max < el1) {
              max = el1;
            }
          }
        });
      });
      let maxint = Math.ceil(max / 9.5); //不让最高的值超过最上面的刻度
      let maxval = maxint * 10; //让显示的刻度是整数
      return maxval;
    },
  },
};
</script>
